import React from "react";

const FormButton = ({ onClick = () => alert("click!"), children }) => {
  return (
    <button onClick={onClick} className="px-4 py-2 flex-1 flex items-center gap-2 text-lg border border-zinc-300 rounded-lg font-semibold hover:bg-zinc-200/40 hover:shadow-md dark:text-zinc-200 dark:hover:bg-sky-700 transition-colors ease-in-out">
      {children}
    </button>
  );
};

export default FormButton;
