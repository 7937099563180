const sources = [
  { key: "landreg", name: "UK Land Registry (HPI)" },
  { key: "imf", name: "International Monetary Fund (IMF)" },
  { key: "nbsc", name: "National Bureau of Statistics of China" },
  {
    key: "oecd",
    name: "Organisation for Economic Co-operation and Development (OECD)",
  },
  { key: "mofj", name: "Ministry of Finance Japan" },
  { key: "boe", name: "Bank of England" },
  { key: "jodi", name: "Oil World Database (JODI)" },
  { key: "bls", name: "US Bureau of Labor Statistics" },
  { key: "ons", name: "UK Office of National Statistics (ONS)" },
  { key: "fred", name: "US Federal Reserve Economic Data (FRED)" },
  { key: "jodigas", name: "Gas World Database (JODI)" },
  { key: "opec", name: "Organization of Petroleum Exporting Countries (OPEC)" },
  { key: "ecb", name: "European Central Bank (ECB)" },
  { key: "worldbank", name: "World Bank" },
  { key: "citypop", name: "City Populations from Thomas Brinkhoff" },
  { key: "economist", name: "The Economist - Big Mac Index" },
  { key: "piketty", name: "Thomas Piketty Data" },
  { key: "eia", name: "US Energy Information Administration (EIA)" },
  { key: "aubs", name: "Australian Bureau of Statistics" },
  {
    key: "fao",
    name: "Food and Agriculture Organization of the United Nations (FAO)",
  },
  {
    key: "bp",
    name: "BP - British Petroleum",
  },
  {
    key: "bchain",
    name: "blockchain.com",
  },
];

const getDataSources = () => {
  // we may move this data to firebase so for now, deliver it using a promise so client code won't need to change when we do
  return new Promise(async (resolve, reject) => {
    try {
      resolve(sources);
    } catch (error) {
      reject(error);
    }
  });
};

export default getDataSources;

export { sources };
