import { BrowserRouter, Route, Routes } from "react-router-dom";

import DatasetPage from "../../view/DatasetPage";
import DataSourceListPage from "../../sources/DataSourceListPage";
import EditMashupPage from "../../mashup/EditMashupPage";
import EmailLinkPage from "../../_app/auth/email/EmailLinkPage";
import EmailLoginPage from "../auth/signin/EmailLoginPage";
import LoginPage from "../auth/signin/LoginPage";
import NewMashupPage from "../../mashup/NewMashupPage";
import Page404 from "./Page404";
import Parameterised from "./Parameterised";
import PrivateMashupChartPage from "../../charts/pages/PrivateMashupChartPage";
import UserMashupsPage from "../../user/mashups/UserMashupsPage";
import SigninPage from "../auth/login/SigninPage";
import LandingPage from "../../home/LandingPage";
import Playpen from "../../_demo/Playpen";

const AppRouter = () => {
    return (
        <BrowserRouter>
          <Routes>
            <Route element={<Playpen />} path="/fiddle" />
            <Route element={<Parameterised component={<NewMashupPage />} />} path="/" />
            <Route element={<Parameterised component={<NewMashupPage />} />} path="/mashup" />
            <Route element={<Parameterised component={<NewMashupPage />} />} path="/mashup/new" />
            <Route element={<Parameterised component={<EditMashupPage />} />} path="/mashup/:id" />
            <Route element={<Parameterised component={<PrivateMashupChartPage />} />} path="/mashup/private/chart/:id" />
            <Route element={<UserMashupsPage />} path="/mashups" />
            <Route element={<EmailLoginPage />} path="/login/email" />
            <Route element={<EmailLinkPage />} path="/login/link" />
            <Route element={<LoginPage />} path="/login" />
            <Route element={<Page404 />} path="*" />
            <Route element={<Parameterised component={<DatasetPage />} />} path="/view/:id" />
            <Route element={<DataSourceListPage />} path="/sources" />
            <Route element={<SigninPage />} path="/signin" />
            <Route element={<LandingPage />} path="/home" />
          </Routes>
        </BrowserRouter>
      );
};

export default AppRouter;   