import InputLabel from "./InputLabel";
import TextInput from "../_app/components/text/TextInput";

export default function LabelledTextInput({
  id,
  name,
  label,
  onChange,
  onKeyDown,
  autoComplete,
  placeholder,
}) {
  return (
    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="sm:col-span-4">
        {label && <InputLabel text={label} forElementId={id} />}
        <div className="mt-2">
          <div className="flex sm:max-w-md">
            <TextInput
              type="text"
              id={id}
              name={name}
              onChange={onChange}
              onKeyDown={onKeyDown}
              autoComplete={autoComplete}
              placeholder={placeholder}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
