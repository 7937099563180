import { Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import convertDataset from "../../_services/factivated/datasets/convertDataset";
import convertVector from "../../_services/factivated/datasets/convertVector";
import toPascalCase from "../../_common/lib/toPascalCase";
import IntroText from "../../_app/components/text/IntroText";
import { UnstyledAppIcon } from "../../_app/components/icons/AppIcon";
import Tooltipper from "../../_app/components/Tooltipper";

const DatasetResult = ({ dataset = {}, onClick = console.log }) => {
  const {
    dataset_key = "",
    tag_category = "",
    description = "",
    series = [],
    publisher_name = "",
  } = dataset;

  return (
    <div key={dataset_key} className="flex flex-col">
      <div className="flex flex-row items-center justify-between">
        <span className="text-lg text-primary-400 dark:text-primary-300">
          <strong>{publisher_name}</strong> {toPascalCase(tag_category)}
        </span>
        <div className="text-primary-400 dark:text-primary-400">
          <Tooltipper tip={description}>
            <UnstyledAppIcon iconKey="help" />
          </Tooltipper>
        </div>
      </div>
      <span
        className="text-md text-zinc-800 dark:text-zinc-300"
        variant="subtitle1"
      >
        <IntroText text={description} />
      </span>
      <div className="flex flex-row flex-wrap gap-2 mt-2">
        {series?.map((item = {}) => {
          return (
            <div
              className="px-2 py-1 text-sm bg-zinc-100 dark:bg-zinc-600 dark:text-zinc-200 border border-zinc-300 dark:border-transparent rounded-md hover:font-bold cursor-pointer"
              key={item.name}
            >
              <div
                onClick={() =>
                  onClick({
                    dataset: convertDataset(dataset),
                    vector: convertVector(dataset, item),
                  })
                }
              >
                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                {item.description}
              </div>
            </div>
          );
        })}
      </div>
      <Divider style={{ marginTop: "1rem" }} />
    </div>
  );
};

export default DatasetResult;
