import { useContext } from "react";

import MashupContext from "../_services/factivated/mashups/api/MashupContext";
import MashupController from "./MashupController";

const NewMashupPage = ({ query = {} }) => {
  const { createBlankMashup } = useContext(MashupContext);
  const { pid } = query;
  const mashup = createBlankMashup();
  if (pid) {
    // if we have been passed a publisher id then add it to the criteria
    mashup.criteria = [`@${pid}`];
  }
  
  return <MashupController mashup={mashup}/>;
};

export default NewMashupPage;
