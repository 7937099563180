import getVectorDescription from "../_services/factivated/datasets/getVectorDescription";
import Tooltipper from "../_app/components/Tooltipper";
import TextInput from "../_app/components/text/TextInput";
import SimpleIconButton from "../_app/components/buttons/SimpleIconButton";

const MashupSettings = ({ mashup = {}, actions = {} }) => {
  const { vectors = [], datasets = [], title = "Untitled" } = mashup;

  const { onChange, onRemoveVector, onVectorEdit } = actions;

  if (vectors.length === 0) return <></>;

  return (
    <div className="flex flex-col gap-6 max-w-fit">
      <div className="flex flex-col gap-2">
        <div className="dark:text-primary-300">Title</div>
        <TextInput
          initialValue={title}
          onChange={(value) => onChange({ title: value })}
          placeholder="mashup title"
          id="6da697b5-6036-48aa-b964-4482f2f381ca"
        />
      </div>
      {vectors.map((item, column) => {
        const { alias, description, multiplier, vector_id } = item;

        const tip = getVectorDescription({ datasets, vector: item });

        return (
          <div className="flex flex-row items-center gap-4" key={vector_id}>
            <div className="dark:text-primary-300">{`Column ${
              column + 1
            }`}</div>
            <div className="min-w-[500px]">
              <Tooltipper tip={tip}>
                <TextInput
                  initialValue={alias || description}
                  onChange={(value) => onVectorEdit({ ...item, alias: value })}
                  id="75cbe054-1624-4f29-ad69-852f4a455b76"
                />
              </Tooltipper>
            </div>
            <div className="dark:text-primary-300">Multiplier</div>
            <div className="max-w-[100px]">
              <TextInput
                initialValue={multiplier}
                type="number"
                onChange={(value) =>
                  onVectorEdit({ ...item, multiplier: Number(value) })
                }
                id="db25eff0-0ffe-43e3-911a-8aad4707602c"
              />
            </div>
            <SimpleIconButton
              tip="Delete this column from the mashup"
              onClick={() => onRemoveVector({ vector: item })}
              icon="trash"
              colour="danger"
            />
          </div>
        );
      })}
    </div>
  );
};

export default MashupSettings;
