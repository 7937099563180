import { useContext } from "react";

import AuthContext from "../../../_services/auth/AuthContext";
import LoginForm from "./LoginForm";
import UserMashupsPage from "../../../user/mashups/UserMashupsPage";
import LoggingContext from "../../../_services/logging/LoggingContext";

const SigninPage = () => {
  const logger = useContext(LoggingContext);
  const { authenticatedUser, github, google } = useContext(AuthContext);

  if (authenticatedUser) return <UserMashupsPage />;

  const onGoogleLogin = () => {
    google
      .login({ redirect: true })
      .catch((error) =>
        logger.error({ error, sid: "91325467-b686-4a77-9fa8-38dc3ccc0a29" })
      );
  };

  const onGithubLogin = () => {
    github
      .login({ redirect: true })
      .catch((error) =>
        logger.error({ error, sid: "6422d480-6cd3-45eb-9b74-97e2d5915967" })
      );
  };

  return (
    <div
      style={{
        backgroundImage: "url(/images/data.jpg)",
        backgroundSize: "cover",
      }}
      className="flex flex-col justify-center items-center h-screen"
    >
      <div className="bg-gradient-to-r from-zinc-600 via-white to-zinc-600 bg-clip-text p-4 text-5xl text-transparent mb-8">
        re:factivated
      </div>
      <LoginForm {...{ onGithubLogin, onGoogleLogin }} />
    </div>
  );
};

export default SigninPage;
