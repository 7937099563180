import { useState } from "react";

export default function TextInput({
  id,
  name,
  initialValue = "",
  onChange = () => {},
  onKeyDown = () => {},
  autoComplete,
  placeholder,
  type="text"
}) {
  const [value, setValue] = useState(initialValue);

  if (!id) throw new Error("id attribute is required 😡");


  const handleChange = (update) => {
    setValue(update);
    onChange(update);
  }

  return (
    <input
      id={id}
      className="block flex-1 border w-full rounded-md py-1.5 pl-3 text-gray-90 bg-zinc-200 placeholder:text-gray-400 focus:ring-0 text-lg sm:text-md sm:leading-6"
      defaultValue={initialValue}
      value={value}
      type={type}
      onChange={({ target: { value: text } }) => handleChange(text)}
      onKeyDown={onKeyDown}
      name={name || id}
      autoComplete={autoComplete}
      placeholder={placeholder}
    />
  );
}
