import getZoomFilter from "../../_services/factivated/mashups/lib/getZoomFilter/code";
import sortRows from "../../_services/factivated/mashups/lib/sortRows/code";

const getChartData = (mashup = {}) => {
  const { vectors = [] } = mashup;

  console.log(vectors)

  const zoomFilter = getZoomFilter({ mashup });

  return sortRows({
    rows: [...mashup.rows].filter(zoomFilter),
    sortOrder: -1,
  }).map((item) => {
    const { period = "", values = [] } = item;
    const kv = {};

    vectors.forEach((vector, index) => {
      const multiplier = Number.parseFloat(vector.multiplier);
      const value = values[index];
      if (Boolean(value)) {
        kv[vector.alias || vector.vector_id] = value * (Number.isNaN(multiplier) || !multiplier ? 1.0 : multiplier);
      }
    });

    return {
      period,
      ...kv,
    };
  });
};

export default getChartData;
