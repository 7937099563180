import { useState } from "react";

import LabelledTextInput from "./LabelledTextInput";

const SearchCriteriaInput = ({ criteria, actions = {} }) => {
  const [value, setValue] = useState(criteria || "");
  const { onCriteriaChange } = actions;

  const handleKeyDown = ({ code = "" } = {}) => {
    console.log({ code, value });
    if (/enter/i.test(code)) {
      onCriteriaChange(value);
    }
  };

  return (
    <LabelledTextInput
      label="Search"
      placeholder="e.g. gdp health total"
      onChange={setValue}
      onKeyDown={handleKeyDown}
      id="e02fda71-b9a1-48e4-82c9-6f195a312a6f"
    />
  );

  // return (
  //   <TextField
  //     defaultValue={criteria}
  //     onKeyDown={handleKeyDown}
  //     onChange={({ target: { value: text } }) => setValue(text)}
  //     label="Search for data"
  //     placeholder="e.g. gdp health total"
  //     InputProps={{
  //       startAdornment: (
  //         <InputAdornment position="start">
  //           <SearchIcon />
  //         </InputAdornment>
  //       ),
  //     }}
  //     fullWidth
  //     id="885b0f8c-1ee1-44ae-8615-71f6f3505762"
  //   />
  // );
};

export default SearchCriteriaInput;
