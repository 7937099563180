import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import ChartContainer from "../ChartContainer";
import getLineColour from '../lib/getLineColour';
import getVectorKey from '../lib/getVectorKey';

const ChartContent = ({  mashup = {}, data = [], height, width, fontFamily }) => {
    const { vectors = [] } = mashup;

    return (
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          width={width}
          height={height}
        >
          {vectors.map((vector, index) => {
            return (
              <Bar
                dataKey={getVectorKey(vector)}
                fill={getLineColour(index)}
                key={vector.vector_id}
              />
            );
          })}
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="period" />
          <YAxis />
          <Tooltip />
          <Legend />
        </BarChart>
      </ResponsiveContainer>
    );
  
}

const SimpleBarChartView = ({ mashup }) => {
    return <ChartContainer mashup={mashup} chart={<ChartContent />} />;
}

export default SimpleBarChartView;