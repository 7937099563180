import { useEffect, useState } from "react";

import MultiRangeSlider from "multi-range-slider-react";

import "./slider.css";

import sortRows from "../../_services/factivated/mashups/lib/sortRows/code";

const ZoomSlider = ({ mashup = {}, onChange }) => {
  const [rangeState, setRangeState] = useState();
  const [rowState, setRowState] = useState();
  const [initialState, setInitialState] = useState();

  useEffect(() => {
    // always populate the zoom slider from earliest to latest date (left to right)
    const rows = sortRows({ rows: mashup.rows, sortOrder: -1 });
    setRowState(rows);

    const maxRow = rows.length - 1;
    // zoom has date values in unix format
    const { zoom } = mashup;
    // range holds the ordinal position of the min / max dates in the rows array

    const minIndex = rows.findIndex(({ unix_date }) => unix_date === zoom?.min);
    const maxIndex = rows.findIndex(({ unix_date }) => unix_date === zoom?.max);

    setInitialState({
      min: 0,
      max: maxRow,
      minValue: minIndex === -1 ? 0 : minIndex,
      maxValue: maxIndex === -1 ? maxRow : maxIndex,
    });
  }, [mashup]);

  // console.log("ZoomSlider");

  const handleChange = (range = {}) => {
    // the html input seems to trigger the onInput event when you initially set its value which causes and endless loop of state updates so ...
    if (
      range.minValue !== rangeState?.minValue ||
      range.maxValue !== rangeState?.maxValue
      ) {
      console.log("handleChange", { range, rangeState, initialState });
      setRangeState(range);
    }
  };

  const publishRange = () => {
    if (rangeState) {
      const { minValue, maxValue } = rangeState;
      onChange({
        min: rowState[minValue].unix_date,
        max: rowState[maxValue].unix_date,
      });
    }
  };

  if (!initialState) return <></>;

  return (
    <div className="flex flex-col gap-1">
      <MultiRangeSlider
        baseClassName="fa32ced4-range-slider"
        label={false}
        min={initialState.min}
        max={initialState.max}
        minValue={initialState.minValue}
        maxValue={initialState.maxValue}
        onInput={handleChange}
        onChange={publishRange}
        minCaption=""
        ruler={false}
        barInnerColor="#ACA19A"
        style={{
          border: "none",
          boxShadow: "none",
        }}
      />
      <div className="flex justify-center">
        <span className="text-md font-semibold border border-primary-500 rounded-md px-2 py-1 text-primary-500 dark:text-primary-300">{`${
          rowState[rangeState?.minValue || 0]?.period
        } - ${
          rowState[rangeState?.maxValue || initialState.maxRow]?.period
        }`}</span>
      </div>
    </div>
  );
};

export default ZoomSlider;
