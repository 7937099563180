import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import ChartContainer from "../ChartContainer";
import getLineColour from "../lib/getLineColour";
import getVectorKey from "../lib/getVectorKey";

const AreaChartContent = ({
  mashup = {},
  data = [],
  height,
  width,
  fontFamily,
}) => {
  const { vectors = [] } = mashup;

  return (
    <ResponsiveContainer>
      <AreaChart
        data={data}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        width={width}
        height={height}
      >
        {vectors.map((vector, index) => {
          return (
            <Area
              type="monotone"
              dataKey={getVectorKey(vector)}
              stroke={getLineColour(index)}
              fill={getLineColour(index)}
              key={vector.vector_id}
              connectNulls
              dot={false}
            />
          );
        })}
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="period" interval={20} />
        <YAxis />
        <Tooltip />
        <Legend />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const AreaChartView = ({ mashup }) => {
  return <ChartContainer mashup={mashup} chart={<AreaChartContent />} />;
};

export default AreaChartView;
