import { useContext, useEffect, useState } from "react";
// import { redirect } from 'react-router-dom';

import { getAuth, onAuthStateChanged, signInAnonymously, signOut } from "firebase/auth";

import AuthContext from "./AuthContext";
import { firebaseApp } from "../firebase/config";
import LoggingContext from "../logging/LoggingContext";

// Auth providers
import email from "./providers/EmailAuth";
import github from "./providers/GithubAuth";
import google from "./providers/GoogleAuth";
import FirestoreApi from "../firebase/firestore/FirestoreApi";
import getAuthProviderData from "./getAuthProviderData";

const auth = getAuth(firebaseApp);

const anonymous = {
  login: () => signInAnonymously(auth)
};

const AuthProvider = ({ children }) => {
  const logger = useContext(LoggingContext);
  const [waiting, setWaiting] = useState(true);
  const [authenticatedUser, setAuthenticatedUser] = useState();

  const value = {
    anonymous,
    auth,
    authenticatedUser,
    email,
    google,
    github,
    logout: () => {
      return new Promise((resolve, reject) => {
        signOut(auth)
          .then(() => {
            sessionStorage.clear();
            resolve(true);
          })
          .catch((error) => {
            logger.error({ error, sid: "37578348-2c8f-4a93-aec0-5f3d3cb5ba48" });
            reject(error);
          });
      });
    },
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const { getDocument, addDocument } = new FirestoreApi({ authenticatedUser: user });
        
        // check if this user has an account and create one if not
        getDocument({ path: `users/${user.uid}`, sid: '3a2472c6-8246-4119-83a5-20a4f3197f1a' })
        .then(userDoc => {
          if (!userDoc) {
            const { providerId } = getAuthProviderData({ authenticatedUser: user }) || {};
            addDocument({ path: `users`, id: user.uid, data: { providerId }, sid: 'e782f088-fece-43ef-8021-c3a0ccbabd32'})
            .catch(error => logger.error({ error, sid: 'f08430cf-a231-432b-ba1a-7de623c1e966'}));
          }
        })
        .catch(error => logger.error({ error, sid: 'd7f47812-d5ba-40fc-a3d0-a386109365a9'}));

        setAuthenticatedUser(user);
      } else {
        setAuthenticatedUser(undefined);
      }

      setWaiting(false);
    });

    return unsubscribe;
  }, [logger]);

  return <AuthContext.Provider value={{ ...value }}>{!waiting && children}</AuthContext.Provider>;
};

export default AuthProvider;

export {
  auth
}
