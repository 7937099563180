import Tooltipper from "../Tooltipper";
import IntroText from "../text/IntroText";

const defaultFormatter = (row, field) => row[field];

export default function GridView({ rows = [], columns = [] }) {
  const formatters = columns.map(
    ({ formatter }) => formatter || defaultFormatter
  );

  return (
    <table className="table table-auto">
      <thead className="border-b mb-6">
        <tr>
          {columns.map(({ headerName, field, tip }, index) => {
            return (
              <th
                key={field}
                className="col-auto max-w-[100px] text-left font-semibold text-primary-500 bg-transparent dark:text-primary-300 dark:bg-zinc-800"
              >
                {/* <Tooltip title={tip}> */}
                {index ? (
                  <Tooltipper tip={tip}>
                    <IntroText text={headerName} tip={tip} maxWords={10} />
                  </Tooltipper>
                ) : (
                  headerName
                )}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, r) => {
          return (
            <tr key={`R${r}`}>
              {columns.map((column, c) => {
                const content = formatters[c](row, column.field);
                return (
                  <td
                    key={`R${r}C${c}`}
                    className="text-left whitespace-nowrap pr-2 text-zinc-700 dark:text-zinc-300 dark:bg-zinc-800"
                  >
                    {content}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
