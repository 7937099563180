import Tooltipper from "../../_app/components/Tooltipper";

const NotificationBadge = ({ text, count, onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      class="inline-flex items-center px-2 py-1.5 text-sm font-medium text-center text-white bg-primary-400 hover:bg-primary-300 rounded-lg"
    >
      {text}
      <span class="inline-flex items-center justify-center px-2 ml-2 text-xs font-semibold text-primary-400 bg-white rounded-lg">
        {count}
      </span>
    </button>
  );
};

const PublisherTags = ({
  publisher_tags = [],
  actions = {},
  publishers = [],
  selected = "",
}) => {
  if (publisher_tags.length === 1 && !selected) return <></>;

  const { onPublisherClick: onClick } = actions;

  return (
    <div className="flex flex-wrap gap-2">
      {publisher_tags
        .sort((a, b) => a.key.localeCompare(b.key))
        .map(({ key = "", doc_count = 0 } = {}) => {
          // we're currently using the elasicsearch _index field as aggregations are proving unreliable!
          // and the _index names are pub_{publisher key}
          const pub_key = key.replace("pub_", "");
          const { name = "missing!" } =
            publishers.find((item) => item.key === pub_key) || {};
          return (
            <Tooltipper tip={`${doc_count} datasets available from ${name}`}>
              <NotificationBadge
                key={key}
                text={`@${pub_key.toUpperCase()}`}
                onClick={() => onClick(key)}
                count={doc_count}
              />
            </Tooltipper>
          );
        })}
    </div>
  );
};

export default PublisherTags;
