import { useState } from "react";
import JSONPretty from "react-json-pretty";

import copyToClipboard from "../lib/copyToClipboard";
import onLocalHost from "../lib/onLocalHost";
import sortObjectKeys from "../lib/sortObjectKeys";

const PageDebugInfo = ({ sid = "", data = {}, style = {} }) => {
  const [opacity, setOpacity] = useState(0.1);

  if (!onLocalHost()) return <></>;

  const handleClick = () => {
    copyToClipboard(sid.split("-")[0]);
    if (opacity < 1) {
      setOpacity(opacity + 0.1);
    } else {
      setOpacity(0.1);
    }
  };

  return (
    <JSONPretty
      onClick={handleClick}
      data={{ sid, data: sortObjectKeys({ ...data }) }}
      className="text-zinc-500 mt-8"
    />
  );
};

export default PageDebugInfo;
