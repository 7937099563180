import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormButton from "./FormButton";
import FormTitle from "./FormTitle";
import { faGithub, faGoogle } from "@fortawesome/free-brands-svg-icons";
import FormInputLabel from "./FormInputLabel";

const LoginForm = ({ onGoogleLogin, onGithubLogin }) => {
  return (
    <div className="flex-col max-w-lg p-4 rounded-xl shadow-lg bg-zinc-50 dark:bg-bg-dark-800 md:min-w-[500px]">
      <FormTitle title="Welcome back" />
      <div className="flex flex-row gap-4 mt-4">
        <FormButton onClick={onGoogleLogin}>
          <span className="text-red-500">
            <FontAwesomeIcon icon={faGoogle} />
          </span>
          Log in with Google
        </FormButton>
        <FormButton onClick={onGithubLogin}>
          <span className="text-indigo-600 dark:text-indigo-400">
            <FontAwesomeIcon icon={faGithub} />
          </span>
          Log in with Github
        </FormButton>
      </div>
      <div className="relative flex py-5 items-center">
        <div className="flex-grow border-t border-zinc-400"></div>
        <span className="flex-shrink mx-4 text-zinc-400">or</span>
        <div className="flex-grow border-t border-zinc-400"></div>
      </div>
      <div className="flex flex-col">
        <FormInputLabel title="Email" />
        <input
          type="text"
          className="grow mt-2 mb-4 rounded-md px-2 py-2 bg-zinc-100 border"
          placeholder="Fear not, we hate spam too"
        />
        <FormInputLabel title="Password" />
        <input
          type="text"
          className="grow mt-2 rounded-md px-2 py-2 bg-zinc-100 border"
          placeholder=""
        />
      </div>
      <div className="flex flex-row justify-between mt-6">
        <div>
          <div className="flex gap-2">
            <input type="checkbox" />
            <div className="text-zinc-500 dark:text-zinc-400">
              Remember me
            </div>
          </div>
        </div>
        <div onClick={() => alert("click!")}  className="text-sky-600 dark:text-sky-400 font-semibold cursor-pointer">
          Forgot password?
        </div>
      </div>
      <div className="mt-6 flex">
        <button onClick={() => alert("click!")}  className="cursor-pointer bg-secondary-700 dark:bg-secondary-100 dark:text-zinc-900 text-zinc-50 rounded-md px-4 py-2 flex-grow hover:shadow-lg">
          Sign in to Factivated
        </button>
      </div>
      <div onClick={() => alert("click!")}  className="mt-6 flex gap-2 text-zinc-500 dark:text-zinc-400">Don't have an account yet? <span className="text-sky-600 dark:text-sky-400 font-semibold cursor-pointer">Sign up here</span></div>
    </div>
  );
};

export default LoginForm;
